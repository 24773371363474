import Splide, { Options, PaginationData, PaginationItem } from '@splidejs/splide';

// We need to extend the library (via its own documentation) and add our listeners inside the mount method
// because they are not automatically reattached when the slider is destroyed / remounted at different breakpoints
export const TransitionExtension = (Splide: Splide) => {
    const inactivePageClass = 'carousel-page-inactive';
    const targetSlideClass = 'is-target';
    const originSlideClass = 'is-origin';
    const hideArrowsClass = 'hide-arrows';
    const classToTheRight = 'is-on-right';
    const classToTheLeft = 'is-on-left';

    const trackShift = (newIndex: number, oldIndex: number, destIndex: number) => {
        const SplideEls = Splide.Components.Elements;
        SplideEls.slides[oldIndex].classList.add(originSlideClass);
        SplideEls.slides[newIndex].classList.add(targetSlideClass);

        const direction = newIndex - oldIndex > 0 ? "right" : "left";
        SplideEls.root.setAttribute('data-slide-direction', direction);
        SplideEls.slides[oldIndex].classList.add(originSlideClass);
        SplideEls.slides[newIndex].classList.add(targetSlideClass);
        if (SplideEls.slides[newIndex - 1]) {
            SplideEls.slides[newIndex - 1].classList.add(classToTheLeft);
        }
        if (SplideEls.slides[newIndex + 1]) {
            SplideEls.slides[newIndex + 1].classList.add(classToTheRight);
        }
        if (SplideEls.slides[oldIndex - 1]) {
            SplideEls.slides[oldIndex - 1].classList.remove(classToTheLeft);
        }
        if (SplideEls.slides[oldIndex + 1]) {
            SplideEls.slides[oldIndex + 1].classList.remove(classToTheRight);
        }
    }

    const cleanupShift = (newIndex: number, oldIndex: number, destIndex: number) => {
        const SplideEls = Splide.Components.Elements;
        SplideEls.slides.forEach(slide => {
            slide.classList.remove(targetSlideClass);
            slide.classList.remove(originSlideClass);
        })
    }

    const updatePagination = (data: PaginationData, prevItem: PaginationItem, activeItem: PaginationItem) => {
        data.items.forEach(item => {
            if (item.button != activeItem.button && (!prevItem || item.button != prevItem.button)) {
                item.button.classList.add(inactivePageClass);
            }
            else {
                item.button.classList.remove(inactivePageClass);
            }
        })
    }

    const cleanupUnnecesaryArrows = (options: Options) => {
        const currentOptions = options || Splide.options;
        const slideCount = Splide.Components.Elements.slides.length;
        const perPage = currentOptions.perPage;
        if (perPage >= slideCount) {
            Splide.Components.Elements.root.classList.add(hideArrowsClass);
        }
        else {
            Splide.Components.Elements.root.classList.remove(hideArrowsClass);
        }
    }

    return {
        mount() {
            Splide.on('move', trackShift.bind(this));
            Splide.on('moved', cleanupShift.bind(this));
            Splide.on('ready', cleanupUnnecesaryArrows.bind(this));
            Splide.on('updated', cleanupUnnecesaryArrows.bind(this));
            Splide.on('pagination:updated', updatePagination.bind(this));
            if (Splide.Components.Elements.slides[1]) {
                Splide.Components.Elements.slides[1].classList.add(classToTheRight);
            }
        }
    }
}
import Splide from '@splidejs/splide';
import { TransitionExtension } from '../helpers/carousel-transition-extension';


// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class ArticleCarousel {
    carousel: Splide;
    constructor(element: HTMLElement) {
        this.carousel = new Splide(element, {
            accessibility: true,
            drag: true,
            perPage: 3,
            perMove: 3,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 0,
            keyboard: 'focused',

            gap: '2.5rem',

            classes: {
                pagination: 'splide__pagination article-carousel-pagination carousel-pagination-nums',
                page: 'splide__pagination__page article-carousel-page'
            }
        });

        this.carousel.mount({ TransitionExtension });
    }
}
import { nodeListToArray } from './helpers/nodeListToArray';
import { Accordion } from './modules/accordion';
import { InitializeAnalytics } from './modules/analytics';
import { ArticleCarousel } from './modules/articleCarousel';
import { InitializeBackToTop } from './modules/back-to-top';
import { BlockAnimation } from './modules/block-animation';
import { ExternalModal } from './modules/external-modal';
import { FullBleedImage } from './modules/fullBleedImagesIE';
import { Gallery } from './modules/gallery';
import { globalAlert } from './modules/global-alerts';
import { initializeTabs } from './modules/hover-tabs';
import { InitializeInputMasks } from './modules/input-mask';
import { InitializeLoginForm } from './modules/login-form';
import { Modal } from './modules/modal';
import { mainNavigation } from './modules/nav-desktop';
import { InitializeOnThisPageBlocks } from './modules/onThisPageBlock';
import { initializePageSelect } from './modules/page-select';
import { InitializeSidebar } from './modules/sidebar';
import { SvgSprite } from './modules/svg-loader';
import { InitializeTabbedContent } from './modules/tabbed-block';
import { initializeMobileNav } from './modules/test-mobile';
import { UrlGallery } from "./modules/url-gallery";

(() => {
    //whatInput();

    //Block animation
    new BlockAnimation();

    // SVG Sprite Inject
    new SvgSprite('/Static/img/adage-sprite.svg');

    // Accordion Init
    let accordions = new Accordion({
        oneOpenAtATime: true
    });

    const initArticlearouselBlocks = () => {
        const cardCarouselEls = [].slice.call(document.querySelectorAll('[data-article-carousel]')) as HTMLElement[];
        cardCarouselEls.forEach(cardCarouselEl => {
            new ArticleCarousel(cardCarouselEl);
        })
    }
 
    // Fix images in IE that must be full-bleed
    let fullBleedImages = nodeListToArray(document.querySelectorAll('[data-full-bleed-image] img'));
    fullBleedImages.forEach(imageEl => {
        new FullBleedImage(imageEl);
    })

    // Gallery Init
    // Note: it is recommended to use loop: false when creating a gallery with videos at the beginning/end because of duplicated slides.
    let galleryClass = 'single-item-gallery';
    let galleries = nodeListToArray(document.querySelectorAll('[data-gallery]'));
    galleries.forEach((gallery, index) => {
        gallery.classList.add(`${galleryClass}-${index}`);
        new Gallery({
            containerClass: `${galleryClass}-${index}`,
            slidesPerView: 1,
            navigation: {
                nextEl: '.adage-gallery-btn-next',
                prevEl: '.adage-gallery-btn-prev'
            }
        })
    });

    initArticlearouselBlocks();
    initializePageSelect();
    initializeTabs();
    initializeMobileNav();
    InitializeBackToTop();
    InitializeOnThisPageBlocks();
    InitializeTabbedContent();
    InitializeSidebar();
    InitializeLoginForm();
    InitializeInputMasks();
    InitializeAnalytics();
    globalAlert();
   // headerIconFocus();

    //Main navigation init
    const mainNav = document.querySelector('.cpgms-nav');
    let navElement = new mainNavigation(mainNav as HTMLElement);
    

    let urlGallery = new UrlGallery("player.vimeo.com", "www.youtube.com/embed");
    let externalModal = new ExternalModal('cpg.support.me');

    // Video Modals Init
    let modals = nodeListToArray(document.querySelectorAll('[data-modal]'));
    modals.forEach(modal => {
        new Modal(modal);
    })
})();

